import React from "react";
import { NavLink } from "react-router-dom";

const Footer = () => {
  return (
    <div className="gradient-blue lg:px-10 px-5 py-5 md:py-10">
      <div className="lg:grid lg:grid-cols-3 md:grid md:grid-cols-2 flex flex-col text-white ">
        <div className="flex flex-col gap-3 my-4 ">
          <NavLink to="/about">About Us</NavLink>
          <NavLink to="/contact">Contact Us</NavLink>
          <NavLink to="/Payments">Payments</NavLink>
        </div>
        {/* <div className="flex flex-col gap-3 my-4 ">
          <NavLink to="/promotionalSms">Promotional Sms</NavLink>
          <NavLink to="/web-design">Website Design</NavLink>
          <NavLink to="/promotional-email">Email Marketing</NavLink>
          <NavLink to="/ivr-services">Other Services</NavLink>
        </div> */}

        <div className="flex flex-col gap-3 my-4 ">
          <NavLink to="/privacy-policy">Privacy Policy</NavLink>
          <NavLink to="/RefundAndCancellation">Refund and Cancellation</NavLink>
          <NavLink to="/terms">Terms and Conditions</NavLink>
          <NavLink to="/disclimar">Disclimer</NavLink>
          <div className="flex gap-3 my-1 ">
          <a href="https://www.facebook.com/surajenterprise9" target="_blank" rel="noopener noreferrer">
        <i className="text-2xl cursor-pointer fa-brands fa-facebook"></i>
      </a>

      <a href="https://www.instagram.com/oneclickonesolution" target="_blank" rel="noopener noreferrer">
        <i className="text-2xl cursor-pointer fa-brands fa-instagram"></i>
      </a>

      <a href="https://www.youtube.com/channel" target="_blank" rel="noopener noreferrer">
        <i className="text-2xl cursor-pointer fa-brands fa-youtube"></i>
      </a>

      <a href="https://www.linkedin.com/company/emediaenterprise/" target="_blank" rel="noopener noreferrer">
        <i className="text-2xl cursor-pointer fa-brands fa-linkedin"></i>
      </a>

          </div>
        </div>
      </div>
      {/* <div>
        <h5 className="text-white text-center">
          Copyright © 2009 - 2021{" "}
          <a href="/" className="text-black font-medium">
            Bulk SMS Root India - A unit of Software Labz
          </a>{" "}
          All Rights Reserved
        </h5>
        <a href="/" className="text-4xl block text-[#222] my-6">
          Best Bulk SMS Service
        </a>
        <a href="/" className="text-[#222]">
          Bulk Sms Service Providers in india. Bulksms Root is the best bulk sms
          company in bangalore, india offers Bulk Sms marketing, online sms
          promotions, Promotional Bulk Sms, Transactional bulk sms, Cheapest
          bulk sms plan with API, Bulk Sms Gateway service, cheap bulk sms, buy
          cheap bulk sms online. Cheapest Bulk Sms packs for Promotional sms &
          Transactional Sms. Send Bulksms across india @ lowest rates.
        </a>
        <p className="text-white mt-10">
          * Unlimited validity for all online SMS packs, But 01recharge is
          mandatory in a year to extend the validity
        </p>
      </div> */}
    </div>
  );
};

export default Footer;
