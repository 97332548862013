import React, { useRef } from "react";
import Slider from "react-slick";

import img1 from "../assets/clients/001.jpg";
import img2 from "../assets/clients/002.png";
import img3 from "../assets/clients/003.png";
import img4 from "../assets/clients/004.jpg";
import img5 from "../assets/clients/005.jpg";
import img6 from "../assets/clients/006.jpg";
import img7 from "../assets/clients/007.png";
import img8 from "../assets/clients/008.jpg";
import img9 from "../assets/clients/009.png";
import img10 from "../assets/clients/010.png";
import img11 from "../assets/clients/011.jpg";
import img12 from "../assets/clients/012.jpg";
import img13 from "../assets/clients/013.png";
import img14 from "../assets/clients/014.jpg";
import img15 from "../assets/clients/015.png";
import img16 from "../assets/clients/016.png";
import img17 from "../assets/clients/017.webp";

import playstore from "../assets/playstore.png";

const About = ({ aboutRef }) => {
  const images = [
    img1,
    img2,
    img3,
    img4,
    img5,
    img6,
    img7,
    img8,
    img9,
    img10,
    img11,
    img12,
    img13,
    img14,
    img15,
    img16,
    img17,
  ];
  const clientData = [
    {
      cli: "To handle the SEO, we collaborated with SurajEnterprise. Their staff and services are highly competitive. WordPress, HubSpot, and other hosting names were among those we had. For all of these domains, the staff managed SEO very well, both on and off page.",
      cliName: "Chaitanya, Andhra Pradesh",
    },
    {
      cli: "We have been collaborating with Suraj Enterprise since 2008 on our website for outsourced bookkeeping, and I'm happy to report that we are extremely happy with their website design and development, SEO, and SMO services. Suraj Enterprise has been able to make a big difference in a brief amount of time. Very pleased with the outcomes, which enhanced our online profile and increased our reach. They always speak clearly and effectively, and they are devoted to their work. As a result, we have expanded our collaboration by offering them Back Office Accountants as well as our second assignment.    We would gladly recommend Suraj Enterprise to anyone seeking for a result-driven digital marketing agency, speaking from personal experience.",
      cliName: "Aswin Kumar G, Gujarat",
    },
    {
      cli: "Long before Suraj Enterprise handled my online company presence, I had a relationship with them. My wife found Suraj Enterprise while looking for a reputable SEO/SMO/web creator, and she hasn't turned back since. It was a no-brainer to hire Suraj Enterprise for my online campaign after seeing firsthand how effective they were at boosting online presence. Suraj Enterprise has performed as anticipated in all areas, including website design and online marketing.    Their ability to exchange information with customers in a transparent manner is one of their strengths. There are no empty promises, and they have always conveyed clearly. When I moved to a new city, they were very accommodating in helping me transition my company pages. They are meticulous professionals who you can rely on to market your company.    The standard of the content Suraj Enterprise employs to advertise your company online is last but certainly not least. They have done extensive research on the topic and put your content in the public domain. You'll know Suraj Enterprise does quality work when your customers tell you that they found you while looking for the best company in town. I've lost track of how many times my clients have said things like this to me. Suraj Enterprise is your one-stop business solution if you're searching for a long-standing, reliable, results-driven, and honest company to help you grow your business online. As I continue to work with them, I wish them nothing but success in their development.",
      cliName: "Rajesh M, Hyderabad",
    },
    {
      cli: "The opportunity to evaluate Suraj Enterprise fills me with joy. It is extremely competent and provides outstanding service. I strongly advise the same.      ",
      cliName: "Morganii, Coimbatore.      ",
    },
    {
      cli: "Exceptional Internet marketing experts and innovative coders make up the Suraj Enterprise team. They have exceptional knowledge of affiliate marketing and search engine marketing (SEM). Our company has grown because of their genuine passion for putting internet marketing strategies into practice. Suraj Enterprise is a fantastic choice for all your internet marketing requirements.      ",
      cliName: "Bhanu Prasad – Andhra Pradesh      ",
    },
    {
      cli: "We have been working together with Suraj Enterprise for just over 3 years. We had the pleasure of enlisting the assistance of a highly capable and enthusiastic team during this period, and they promptly fulfilled their commitments. We highly suggest their services to anyone in need of web-based solutions because of their impressive proficiency. They have our sincere gratitude for helping to increase our visibility to our helpless customers.",
      cliName: "Mithra Jobs – Hyderabad.      ",
    },
    {
      cli: "I am very happy with the services that Suraj Enterprise team has offered. Particularly Sirisha was very accommodating and always on hand to help, even on vacations.      ",
      cliName: "Harsha Vardhan K, Andhra Pradesh",
    },
    {
      cli: "We have been working together with Suraj Enterprise for more than two years, and we appreciate their professionalism and positive outlook on social media marketing. Their approach to any problems we have faced is objective and methodical.      ",
      cliName: "Venkat K, Hyderabad.      ",
    },
  ];
  var settingsData = {
    infinite: true,
    slidesToShow: 1,
    autoplay: true,
    adaptiveHeight: true,
  };
  var settings = {
    dots: true,
    infinite: true,
    slidesToShow: 4,
    autoplay: true,
    slidesToScroll: 1,
    arrows: true,
    autoplaySpeed: 5000,
    pauseOnHover: false,
    responsive: [
      {
        breakpoint: 1023,
        settings: {
          slidesToShow: 4,
          slidesToScroll: 1,
          infinite: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
          dots: false,
          initialSlide: 2,
        },
      },
    ],
  };

  return (
    <section ref={aboutRef}>
     
      {/* PLAY-STORE */}
      <div className="my-10">
        <h1 className="text-center font-medium text-xl">
          Still Have Question? Feel free to email us at sales@bulksmsgateway.in
          or Call +91 9912340901
        </h1>
        <div className="lg:px-20 px-10 py-10 lg:grid lg:grid-cols-4 gap-8 md:grid md:grid-cols-2 flex flex-col">
          <div className="flex flex-col justify-center shadow-card cursor-pointer  gap-2 rounded-lg p-4 items-center">
            <i class="fa-solid fa-face-smile text-3xl"></i>
            <h1 className="font-medium text-2xl">500 +</h1>
            <h2 className="font-medium">Projects done</h2>
          </div>
          <div className="flex flex-col justify-center shadow-card cursor-pointer  gap-2 rounded-lg p-4 items-center">
            <i class="fa-solid fa-users text-3xl"></i>
            <h1 className="font-medium text-2xl">52</h1>
            <h2 className="font-medium">Happy Clients</h2>
          </div>
          <div className="flex flex-col justify-center shadow-card cursor-pointer  gap-2 rounded-lg p-4 items-center">
            <i class="fa-solid fa-thumbs-up text-3xl"></i>
            <h1 className="font-medium text-2xl">90 %</h1>
            <h2 className="font-medium">Success Rate</h2>
          </div>
          <div className="flex flex-col justify-center shadow-card cursor-pointer items-center gap-2 rounded-lg p-4">
            <i class="fa-solid fa-award text-4xl"></i>
            <h1 className="font-medium text-2xl">10</h1>
            <h2 className="font-medium">Awards</h2>
          </div>
        </div>

        <h2 className="text-center text-2xl mb-4 font-semibold">
          Download the app now{" "}
        </h2>
        <div className="flex justify-center items-center">
          <a
            href="https://play.google.com/store/apps/details?id=com.surajsolution.enterprise"
            className=" flex gap-4 py-2 px-8 border  rounded-lg items-center"
          >
            {/* <i className="fa-brands fa-google-play text-4xl"></i> */}
            <img src={playstore} alt="" className="w-12" />
            <div className="grid cursor-pointer">
              <small className="uppercase">Get it on</small>
              <span className="text-lg font-medium">Google Play</span>
            </div>
          </a>
        </div>
      </div>

      <div className="about-bg lg:px-20 px-10 py-10">
        <h1 className="text-center font-medium text-4xl">
          Welcome To Suraj Enterprise
        </h1>
        <h2 className="text-center text-lg mt-4">
          We think of time as a resource and believe that the more time you save
          on a project, the more money you save. Cost efficiency is directly
          proportional to on-time delivery.
        </h2>
        <h1 className="text-2xl font-medium text-center mt-4">
          Our Application
        </h1>
        <div className="lg:grid lg:grid-cols-2 gap-8 flex flex-col  mt-4 ">
          <Card2
            title="Mobile Apps / Responsive"
            desc="The responsive websites we build are designed to respond or adapt to the type of computing device or technology used by our visitors. Ensure your website is viewable regardless of device size and ensure your visitors have a similar experience on other devices. "
          />
          <Card2
            title="Web Panel (Browser) & Mobile Application "
            desc="The web panel we designed follows the trend of streamlining modern web browsers by providing users with a range of great features, choices and customization options they need to manage their customers and their responses. . "
          />
          </div>
      </div>

      {/* TESTIMINOAL */}
      <div className="lg:px-20 px-10 py-10">
        <h1 className="text-center font-medium text-4xl mb-8">
          Clients Testimonial
        </h1>
        <Slider {...settingsData}>
          {clientData.map((data) => {
            return (
              <div key={data} className="">
                <h2 className="text-justify">{data.cli}</h2>
                <h2 className="font-semibold text-right mt-4">
                  {data.cliName}
                </h2>
              </div>
            );
          })}
        </Slider>
        {/* <div className="lg:grid  gap-8 flex flex-col ">
          <div className="text-center">
            <h4>
              "The team are ultimate rock stars! They are a highly professional,
              educated and experienced team that is dedicated to helping their
              clients achieve success. One of the best teams I have ever worked
              with – highly responsive, innovative and leverage best practices.
              From kick-off to project completion, they never miss a beat and
              are always there to answer questions and provide intelligent
              insight. Looking forward to many more projects ahead!" <br />
              <div className="text-right font-semibold"> Kumarswami - TN</div>
            </h4>
          </div>
        </div> */}
      </div>

      {/* OUR CLIENTS */}
      <div className="lg:px-20 px-10 py-10">
        <h1 className="text-center font-medium text-4xl mb-8">Our Clients</h1>
        <Slider {...settings}>
          {images.map((image) => {
            return (
              <div key={image} className="partners">
                <img src={image} alt="" className="md:w-44 w-32" />
              </div>
            );
          })}
        </Slider>
      </div>
    </section>
  );
};

export default About;

function Card({ title, desc }) {
  return (
    <div className="mt-8 text-gray px-4 text-center ">
      <h1 className="font-medium text-lg about-line">{title}</h1>
      <p className="text-gray2 mt-4">{desc}</p>
    </div>
  );
}

function Card2({ title, desc }) {
  return (
    <div className="mt-8 text-gray px-4  ">
      <div className="flex gap-4 items-center">
        <h1 className="font-medium text-lg">{title}</h1>
        <i class="fa-solid fa-screwdriver-wrench text-3xl"></i>
      </div>
      <p className="text-gray2 mt-4">{desc}</p>
    </div>
  );
}
