import React from "react";
import Footer from "../components/Footer";

const AwarenessProgram = () => {
  return (
    <>
      <div className="mt-28 mb-10 lg:mx-40 mx-8 lg:p-12 p-8 shadow-2xl">
        <h1 className="text-2xl font-medium">Sports Event for School Children</h1>

        <h2 className="mt-4">
          Join us for a 3 day of fun and awareness through sports! Each participant will be charged a fee of <strong>₹500/-</strong>.
        </h2>

        <h1 className="text-2xl font-medium mt-8">Payment Policy:</h1>
        <h2 className="mt-2">
          Payment is accepted only through online methods. We do not accept cash transactions.
        </h2>

        <h1 className="text-2xl font-medium mt-8">Final Order Confirmation:</h1>
        <h2 className="mt-2">
          Once your registration is confirmed, we cannot accept cancellations / Transfers.
        </h2>
        
        <h1 className="text-2xl font-medium mt-8">Third-Party Commitment</h1>
        <h2 className="mt-2">
          As a third-party service provider, we allocate resources based on your confirmation, making last-minute changes difficult.
        </h2>

        <h1 className="text-2xl font-medium mt-8">Dedicated Resources</h1>
        <h2 className="mt-2">
          Your registration triggers a commitment of time and effort from our team.
        </h2>

        <h1 className="text-2xl font-medium mt-8">Modifications</h1>
        <h2 className="mt-2">
          If you need to adjust your registration, please contact us as soon as possible. We’ll do our best to accommodate your request.
        </h2>

        <h1 className="text-2xl font-medium mt-8">Efficiency Assurance</h1>
        <h2 className="mt-2">
          This policy is designed to ensure smooth operations and high-quality service for all participants.
        </h2>

        <h1 className="text-2xl font-medium mt-8">Thank You for Your Understanding</h1>
        <h2 className="mt-2">
          We appreciate your support and understanding of our policy.
        </h2>
      </div>

      <Footer />
    </>
  );
};

export default AwarenessProgram;
