import React from "react";
import Footer from "../components/Footer";

const Contact = () => {
  return (
    <div className="mt-28 ">
      <div className="lg:px-20 px-8 bg-contact text-white h-[50vh] py-20 text-center flex justify-center items-center flex-col relative">
        <h1 className="lg:text-4xl text-2xl font-medium z-10">
          We'd love to hear from you
        </h1>
        <h2 className="mt-2 z-10">
          Whether you have a question about features, trials, pricing, need a
          demo, or anything else, our team is ready to answer all your questions
        </h2>
        <div className="overlay-con absolute top-0 left-0 w-full h-full"></div>
      </div>
      <div className="lg:px-20 px-8 lg:grid py-10 lg:grid-cols-2 items-center flex flex-col bg-[#686868]">
        <div className="text-white">
          <h1 className="text-white text-2xl font-medium">Branches</h1>
          <h4 className="mt-4">
            <span className="font-medium">AndhraPradesh</span># Opp: Post Office,
            Gandhinagar, Vijayawada - 520003, Andhra Pradesh, INDIA. | +91
            9494476996
          </h4>
          <h4 className="mt-4">
            <span className="font-medium">Telangana</span># Flat No: 6, Near
            Kalyan Nagar Phase - 1, S.R. Nagar, Ameerpet, Hyderabad,
            Telangana-500038. | +91 9912340901
            <br></br><br/># Flat No: 204, Near GHMC Play Park, Road No1, KPHB Colony,
            Hyderabad, Telangana - 500072. | +91 9912340901
          </h4>
          <h4 className="mt-4">
            <span className="font-medium">Gujarat</span># 22, Chatrapati Shivaji
            Marg Bungalow Society, Akshar Dham Society, Adajan, Surat, Gujarat
            395009 | +91 6281668428
          </h4>
        </div>
        <div className="bg-white grid px-4 py-4 banner-input w-full mt-10 lg:mt-0">
  <input type="text" placeholder="Your Name" />
  <input type="text" placeholder="Phone Number" />
  <input type="text" placeholder="Email Address" />
  <textarea placeholder="Your Message" rows="2"></textarea>
          {/* <input type="text" placeholder="Enter Captcha" /> */}
{/* Agree to terms and conditions checkbox */}
<div className="flex items-center mt-2">

  </div>
            {/* Submit button */}
            <button className="text-center mt-2 btn-gradient text-white px-6 py-2 w-[150px] rounded-full">
            Submit
          </button>
          {/* <div class="container">
            <div class="center">
              <button class="btn">
                <svg
                  width="120px"
                  height="40px"
                  viewBox="0 0 180 60"
                  class="border"
                >
                  <polyline
                    points="179,1 179,59 1,59 1,1 179,1"
                    class="bg-line"
                  />
                  <polyline
                    points="179,1 179,59 1,59 1,1 179,1"
                    class="hl-line"
                  />
                </svg>
                <span>Submit</span>
              </button>
            </div>
          </div> */}
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default Contact;
